import { useNavigate } from 'react-router'
import { motion } from "framer-motion"

import './Profiles.css'
import Rose from './rose.png'
import Lamp from './lamp.png'

import Gold from '../img/gold.png'
import Graffiti from '../img/graffiti.png'
import Chest from '../img/chest.png'
import Perks from '../img/perks.png'

export default function Profiles({
	page, opening,
	openMain
}) {
	const navigate = useNavigate()
	
	const goToForum = () => {
		openMain()
		navigate("/")
	}
	
	
	if(page !== 'profiles') return <></>
	
	return (<motion.div
	initial={{ opacity: 0 }}
	animate={
		opening || page !== 'profiles' ? { opacity: 0 } : { opacity: 1 }
	}
	className="profiles">
		<div className="column">
			<div 
			onClick={goToForum}
			className="go-back">
				<a>На форум</a>
			</div>
			<div className="row">
				<div className="account">
					<img src={Rose} className="rose"/>
					<img src={Lamp} className="lamp"/>
					<a className="title">Аккаунт форума</a>
					<div className="border">
					<div className="top">
						Аусие
					</div>
					<div className="bottom">
						<div><a>Почта: </a><a style={{color:'#8CFFA5'}}>bachkac@yandex.ru</a></div>
						<a>Посл. привязка: 1 нед. назад</a>
						<a style={{ marginTop: '7px' }}>На сайте с: 1.03.2022</a>
						<div><a>Принятых жалоб: </a><a style={{color:'#85FF91'}}>0</a></div>
					</div>
					</div>
				</div>
				<div className="profile">
					<a className="title">Игровой профиль</a>
					<div className="border">
					<div className="top">
						Не привязан!
					</div>
					<div className="bottom">
						<div className="rank"><a className="text">Ранг: </a><a className="name">ИГРОК</a></div>
						<div className="inside">
							<div><a>Привязан </a><a style={{ color: '#A3FF9B' }}>1 нед. назад</a></div>
							<div><a>Наиграно </a><a style={{ color: '#C19BFF' }}>12 часов</a></div>
							<div><a>Получено </a><a style={{ color: '#FF9393' }}>0 блокировок</a></div>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div
			style={{ marginBottom: '50px' }}
			className="row">
				<div className="column">
					<div className="buttons">
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Видимое имя</a>
						</motion.div>
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить пароль</a>
						</motion.div>
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить почту</a>
						</motion.div>
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Выйти из аккаунта</a>
						</motion.div>
					</div>
					<div
					style={{ marginTop: '30px' }}
					className="buttons">
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Привязать профиль</a>
						</motion.div>
						<motion.div 
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить никнейм</a>
						</motion.div>
					</div>
				</div>
				
				<div className="inventory">
					<a className="title">Игровой инвентарь</a>
					<div className="border inside">
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Gold}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Graffiti}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Chest}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Perks}/>
							<a>0</a>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	</motion.div>)
}