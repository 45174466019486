//import axios from 'axios'
//axios.defaults.withCredentials = true
let routes = [0]//localStorage.getItem("routes") ? JSON.parse(localStorage.getItem("routes")) : [ 0 ]

let isRefreshing = false

const get = async (route, body, isUnauthorized) => {
	try {
		const headers = {
			'Content-Type': 'application/json;charset=utf-8'
		}
		
		const token = localStorage.getItem('token')
		if(token && !isUnauthorized) headers['Authorization'] = 'Bearer ' + token
		
		const response = await fetch('https://www.awzone.net/api/' + route, {
			method: 'POST',
			credentials: 'include',
			headers,
			cache: 'no-store',
			body: JSON.stringify(body)
		})
		
		const responseJson = await response.json()
		
		if(response.status === 401) {
			
			console.log('1111!')
			
			if(isRefreshing) await new Promise(r => setTimeout(r, 2000))
			else {
				console.log('Refreshing token...')
				isRefreshing = true
				await updateRefresh()
				isRefreshing = false
			}
			
			//window.location.reload()
			//window.location.replace("https://www.awzone.net")
			return await get(route, body)
		}
		
		return responseJson
	} catch (e) {
		console.log(e)
		return {}
	}
}

/* Обновление токена доступа */
/* сгорает через 15 минут, требует повторного обновления */
/* обновляет и токен доступа, и специальный токен обновления */
/* В случае взлома, специальный токен обновления изменится, и владелец аккаунта */
/* не сможет получить новый токен доступа - его выкинет с аккаунта */

const updateRefresh = async () => {
	const refreshToken = localStorage.getItem('refreshToken')
	const token = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	const accessTokenExpires = localStorage.getItem('accessTokenExpires')
	
	//if(!token || !accessTokenExpires || +accessTokenExpires - 3 > Date.now() / 1000) return true
	
	const eps = await get("get_endpoints", {}, true)
	
	console.log(eps)
	
	const response = await get(eps.endpoints[9], { userId, refreshToken }, true)
	
	console.log(response)
	
	if(!response.refreshToken) {
		localStorage.removeItem('userId')
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('token')
		return false
	}
	else {
		localStorage.setItem('refreshToken', response.refreshToken)
		localStorage.setItem('token', response.accessToken)
		localStorage.setItem('accessTokenExpires', response.accessTokenExpires)
		return true
	}
}

/* Обновление эндпоинтов */

const updateEndpoints = async () => {
	console.log('Routes must be updated at: ' + routes[0] + ' | cur ' + Date.now())
	if(routes[0] && routes[0] > Date.now()) return
	const response = await get("get_endpoints", {})
	
	const { endpoints, online } = response
	if(!endpoints) return
	routes = endpoints
	localStorage.setItem("routes", JSON.stringify(endpoints))
	localStorage.setItem("online", JSON.stringify(online))
	return online
}

export default {
	register: async(a, b, c) => {
		await updateEndpoints()
		
		const t = await get(routes[1], {
			name: a,
			password: b,
			email: c
		})
		
		return t
	},
	login: async(a, b) => {
		await updateEndpoints()
		
		const t = await get(routes[2], {
			email: a,
			password: b
		})
		
		return t
	},
	send: async(a, b) => {
		await updateEndpoints()
		
		const t = await get(routes[3], {
			topicId: a,
			content: b,
		})
		
		return t
	},
	edit: async(a, b) => {
		await updateEndpoints()
		
		console.log(b)
		console.log(b)
		console.log(b)
		
		const t = await get(routes[12], {
			id: a,
			content: b,
		})
		
		return t
	},
	create: async(a, b, c) => {
		await updateEndpoints()
		
		const t = await get(routes[3], {
			section: a,
			title: b,
			content: c
		})
		
		return t
	},
	search: async(a) => {
		await updateEndpoints()
		
		const t = await get(routes[4], { text: a })
		
		return t
	},
	get_feed: async() => {
		await updateEndpoints()
		
		const t = await get(routes[11], {})
		
		return t
	},
	get_last_replies: async() => {
		await updateEndpoints()
		
		const t = await get(routes[10], {})
		
		return t
	},
	get_sections: async() => {
		const forum = await fetch("https://www.awzone.net/forum.json", {cache: "no-store"})
		
		return forum.json()
	},
	update_sections: async(setForum, force) => {
		let cachedSections = localStorage.getItem("forum")
		
		if(!cachedSections || force) {
			const { themes, sections } = await (await fetch("https://www.awzone.net/forum.json", {cache: "no-store"})).json()
			
			sections.map((section, id) => {
				const theme = themes[section.theme]
				section.id = id
				if(!theme.sections) theme.sections = [section]
				else theme.sections.push(section)
			})
			
			const forum = { themes, sections }
			setForum(forum)
			localStorage.setItem("forum", JSON.stringify(forum))
		} else {
			setForum(JSON.parse(cachedSections))
		}
	},
	open: async(a, b) => {
		await updateEndpoints()
		
		console.log(routes)
		
		const t = await get(routes[5], { topicId: a, page: b })
		
		return t
	},
	keep_online: async() => {
		await updateEndpoints()
	},
	hide: async(a) => {
		await updateEndpoints()
		
		const t = await get(routes[6], { id: a })
		
		return t
	},
	section: async(a) => {
		await updateEndpoints()
		
		const t = await get(routes[7], { id: a })
		
		return t
	},
	completeRegister: async (a, b, c, d) => {
		await updateEndpoints()
		
		const t = await get(routes[8], { email: a, token: b, eula: c, mailing: d })
		
		return t
	},
}