import { useNavigate } from 'react-router'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { motion } from "framer-motion"
import API from './API.js'

import Indicator from './img/svg/page.svg'

import './main/PageTop.css'

import BackFull from './img/back-full.jpg'
import BackFull4 from './img/4-back-full.png'

import Profiles from './me/Profiles.js'
import Auth from './main/Auth.js'

export default function Main({ 
		screenWidth,
		setLoading, loading,
		opening,
		setPage, page,
		topOpened,
		special, setSpecial,
		openMain, openLogin, openRegister, openTop,
		setWrong, wrong,
		authorized, setAuthorized
	}) {
	
	const [ topHeaderContent, setTopHeaderContent ] = useState(null)
	const navigate = useNavigate()
	const [ search, setSearch ] = useSearchParams()
	
	const state = search.get('route') ? 'auth' : 'none'
	
	const scrollToDownloader = async () => {
		console.log(page)
		
		if(state || page === 'profiles') {
			openMain()
			//await new Promise(resolve => setTimeout(resolve, 700))
		}
		
		if(page === 'feed') {
			const element = document.getElementById("launcher")
			if(element) element.scrollIntoView();
			else navigate("/#launcher")
		} else navigate('/#launcher')
	}
	
	const openProfiles = async () => {
		navigate("/me")
		//openTop()
	}
	
	useEffect(() => {
		let content
		
		const isMobile = screenWidth < 700
		
		if(authorized === true) {
		const name = localStorage.getItem("name")
		
		if(!isMobile) setTopHeaderContent(<div className="swift">
		{/*<a style={{ cursor:'pointer' }} onClick={() => {
				localStorage.removeItem("token")
				localStorage.removeItem("userId")
				setAuthorized(false)
		}}>Выйти</a>
			<a className="max-size-el"> • </a>*/}
			<div style={{ cursor: 'not-allowed' }}>Уведомлений нет</div>
			<div className="max-size-el"> • </div>
			<div
			onClick={ openProfiles }
			style={{ cursor: 'pointer' }}><a
			
			> { name }</a></div>
			<motion.div className="play"
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.9 }}
				onClick={ scrollToDownloader }><a>Играть</a>
			</motion.div>
		</div>)
		else setTopHeaderContent(<div className="swift">
			<div style={{ cursor: 'not-allowed' }}>0</div>
			<div className="max-size-el"> • </div>
			<div style={{ cursor: 'pointer' }}> { name }</div>
		</div>)
	} else if(authorized === false) {
		if(!isMobile) setTopHeaderContent(<div className="swift">
				<div 
				style={{ cursor: 'pointer' }} 
					onClick={ openRegister }>Регистрация</div>
				<div className="max-size-el">&nbsp;•&nbsp;</div>
				<div
				style={{ cursor: 'pointer' }}
				className="auth-login"
					onClick={ openLogin }>Вход</div>
				<motion.div className="play"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.9 }}
					onClick={ scrollToDownloader }><a>Играть</a>
				</motion.div>
			</div>)
		else setTopHeaderContent(<div className="swift">
				<div className="auth-buttons" onClick={ openLogin }>
					<a className="auth-login">Авторизация</a>
				</div>
			</div>)
		
	}
	}, [ screenWidth, authorized ])
	
	const pageIndicator =
		page === 'sections' ? 'Разделы форума' :
		page === 'feed' ? 'Главная' :
		page === 'section' ? 'Раздел форума' :
		page === 'topic' ? 'Тема форума' :
		page === 'profiles' ? 'Личный кабинет' :
		'Форум'
	
	return (<>
			<div className="header">
				<motion.div className="background"/>
				<div className="darking main-page-background"/>
				<div className="top-header">
				<motion.div
				className="indicator">
					<img src={Indicator}/>
					<a>{ pageIndicator }</a>
				</motion.div>
				{ topHeaderContent }</div>
					
				<Profiles
				page={page}
				opening={opening}
				openMain={openMain}
				/>
				
				<Auth
				setWrong={setWrong}
				wrong={wrong}
				openMain={openMain}
				openRegister={openRegister}
				openLogin={openLogin}
				setAuthorized={setAuthorized}/>
				
				<motion.div
				animate={ page === 'profiles' ? { display: 'none' } : { display: 'flex' }}
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95, opacity: 0.9, transition: { duration: 0.1 } }}
				className={`server-title`}>
					<div className="icon"></div>
					<div className={`row`}>
						<a className="network kanit-black">ARTWORLD NETWORK</a>
						<a className="text">НОВЫЙ ИГРОВОЙ ПРОЕКТ</a>
					</div>
				</motion.div>
		</div>
		</>
	)
}